.table-management {
    @extend %as-flex-center;
    position:        relative;
    flex-wrap:       wrap;
    justify-content: space-between;
    margin:          var(--as-margin);
    gap:             var(--as-gap);

    & .left, & .right {
        @extend %as-flex-center;
        flex-grow: 1;
        flex-wrap: wrap;
        gap:       var(--as-gap);
    }

    .left {
        justify-content: flex-start;
        justify-self:    flex-start;
    }

    .right {
        justify-content: flex-end;
        justify-self:    flex-end;
    }

    .indicator {
        width:            0.5rem;
        height:           0.5rem;
        border-radius:    50%;
        background-color: var(--as-IconAccent);
    }
}
