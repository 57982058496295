@import "@campaign-portal/components-library/src/styles/alaris-global";
@import "modules/table";
@import "modules/alaris";
@import "modules/cdk";

:root {
    --as-gap: 1rem;
}

.layout {
    display:         flex;
    align-items:     center;
    flex-direction:  row;
    justify-content: center;
    height:          100vh;
    //background:      linear-gradient(103deg, rgba(2, 0, 36, 1) 0%, rgba(9, 62, 121, 0.3981967787114846) 25%, rgba(0, 212, 255, 1) 100%);
    //background-size: cover;
}

.panel-content {
    overflow-y: auto;
    height:     calc(100vh - (2 * 5rem));
    padding:    2rem 2rem 0 2rem;
    background: var(--as-BgPrimary);
}

.text-trunc {
    display:            -webkit-box;
    overflow:           hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.text-ellipsis {
    overflow:      hidden;
    white-space:   nowrap;
    /* max-width: 158px; */
    text-overflow: ellipsis;
}